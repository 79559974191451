<template>
  <BaseCard
    :caption-visible="false"
    :footer-visible="false"
    actions-visible
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">usuarios</span>
    </template>
    <template #actions>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_USERS,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        :to="{ name: 'createUser' }"
      >
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          + Añadir usuario
        </b-button>
      </b-link>
    </template>
    <UsersTable />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import UsersTable from '@/components/users/table/UsersTable.vue'

export default {
  components: {
    UsersTable,
    BaseCard,
  },
}
</script>
<style scoped>
</style>
