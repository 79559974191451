<template>
  <BaseTable
    ref="users-table"
    :resource="$data.$constants.RESOURCES.RESOURCE_USERS"
    :resourceStore="'users'"
    :columns="columns"
    hide-totals
    hide-upload-button
    hide-printer-button
    hide-filter-button
    :loading="loading"
    @row-clicked="$router.push({ name: 'viewUser', params: { id: $event.id } })"
  >
    <template #cell(name)="data">
      <b-row align-v="center">
        <b-col cols="auto">
          <b-avatar
            size="md"
            :src="data.item.avatar"
          />
        </b-col>
        <b-col>
          <div class="text-break">
            {{ data.item.name }}
          </div>
          <div class="text-light text-break">
            {{ data.item.email }}
          </div>
        </b-col>
      </b-row>
    </template>
    <template #cell(roles)="data">
      <template v-if="data.item.roles">
        <span
          v-for="item in data.item.roles"
          :key="item.id"
          class="mr-50"
        >
          {{ item.display_name }}
        </span>
      </template>
    </template>
    <template #cell(actions)="data">
      <span>
        <b-link
          :to="{ name: 'viewUser', params: { id: data.item.id} }"
          class="pr-1 d-inline-block text-indigo"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Ver"
            icon="EyeIcon"
            size="18"
          />
        </b-link>
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_USERS,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
          class="d-inline-block text-danger"
          @click="deleteUser( data.item.id, data.item.name)"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </b-link>
      </span>
    </template>
  </BaseTable>
</template>

<script>
import UsersApi from '@/api/users-api'
import BaseTable from '@/components/ui/table/BaseTable.vue'

export default {
  name: 'UsersTable',
  components: { BaseTable },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'Nombre',
          key: 'name',
          sortable: true,
        },
        {
          label: 'Rol',
          key: 'roles',
          sortable: true,
          sortKey: 'role',
        },
        {
          label: 'Acciones',
          key: 'actions',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    async deleteUser(id, name) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await UsersApi.delete(id)
        await this.$refs['users-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
